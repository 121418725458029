
import { defineComponent, onMounted } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  components: {
    Header,
    Footer,
  },
  setup() {
    onMounted(() => {
      document.dispatchEvent(new Event('render-event'));
    });
  },
});
