
import {
  computed,
  defineComponent, PropType, ref,
} from 'vue';
import { Scrollbar } from 'swiper';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { useResize } from '@/composables/useResize';
import PurchaseCard from '@/components/PurchaseCard.vue';
import { NFT } from '@/interfaces';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
import 'swiper/modules/navigation/navigation.scss';

export default defineComponent({
  name: 'PurchaseSlider',
  components: {
    Swiper,
    SwiperSlide,
    PurchaseCard,
  },
  emits: ['select'],
  props: {
    data: {
      type: Array as PropType<NFT[]>,
      default: () => [],
    },
    selectedData: {
      type: Object as PropType<NFT>,
      default: () => ({}),
    },
  },
  setup() {
    const swiper = ref<typeof Swiper | null>(null);

    const { vw } = useResize();

    const isMobile = computed(() => vw.value <= 768);

    const onSwiper = (swiperInstance: typeof Swiper) => {
      swiper.value = swiperInstance;
    };

    // const formattedModules = computed(() => {
    //   return isMobile.value
    //     ? [Autoplay]
    //     : [Scrollbar, Autoplay]
    // })

    return {
      swiper,
      onSwiper,
      modules: [Scrollbar],
      isMobile,
    };
  },
});
