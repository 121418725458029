import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { en, tw, cn } from '@/locales';
import App from './App.vue';
import router from './router';

// eslint-disable-next-line import/no-unresolved
import 'windi.css';

const messages = {
  en, tw, cn,
};

const getDefaultLocale = (): string => {
  const userLanguage = navigator.language;

  if (['zh-CN', 'zh-SG'].includes(userLanguage)) {
    return 'cn';
  }

  if (['zh-TW', 'zh-HK'].includes(userLanguage)) {
    return 'tw';
  }

  return 'en';
};

const i18n = createI18n({
  locale: getDefaultLocale(), // set locale
  fallbackLocale: 'en', // set fallback locale
  messages,
});

const app = createApp(App);

app.use(i18n);
app.use(router);
app.mount('#app');
