
import {
  defineComponent, ref, watch,
} from 'vue';
import { Scrollbar, Autoplay } from 'swiper';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { useResize } from '@/composables/useResize';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';
import 'swiper/modules/navigation/navigation.scss';

export default defineComponent({
  name: 'GirlSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiper = ref<typeof Swiper | null>(null);
    const slidePerView = ref(4);

    const { vw } = useResize();

    watch(vw, (newVal) => {
      if (newVal <= 768) {
        slidePerView.value = 1;
        return;
      }
      slidePerView.value = 4;
    }, { deep: true });

    const onSwiper = (swiperInstance: typeof Swiper) => {
      swiper.value = swiperInstance;
    };

    return {
      swiper,
      slidePerView,
      onSwiper,
      modules: [Scrollbar, Autoplay],
    };
  },
});
