
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

import {
  computed,
  defineComponent, onMounted, onUnmounted, ref,
} from 'vue';
import MemberCard from '@/components/MemberCard.vue';
import GirlSlider from '@/components/GirlSlider.vue';
import BannerSlider from '@/components/BannerSlider.vue';
import PurchaseButton from '@/components/PurchaseButton.vue';
import PurchaseSlider from '@/components/PurchaseSlider.vue';
import PurchaseVideo from '@/components/PurchaseVideo.vue';
import PurchaseDialog from '@/components/PurchaseDialog.vue';
import Title from '@/components/SectionTitle.vue';
import {
  connectWallet,
  checkWalletIsValid,
  freeMint,
  publicSaleMint,
  getNFTs,
  reveal,
  isRevealed,
  delay,
  getCurrentNftCount,
  getTotalNftCount,
  whiteListMint,
} from '@/utils';
import { NFT, Wallet, TxResult } from '@/interfaces';
import animateScrollTo from 'animated-scroll-to';
import 'scroll-triggers';
import {
  cloneDeep, filter, throttle, debounce,
} from 'lodash';
import { useI18n } from 'vue-i18n';
import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import objectSupport from 'dayjs/plugin/objectSupport';
import duration from 'dayjs/plugin/duration';
import { useResize } from '@/composables/useResize';

extend(objectSupport);
extend(utc);
extend(timezone);
extend(duration);

interface CountdownTime {
  days: number | string
  hours: number | string
  minutes: number | string
  seconds: number | string
}

type MintType = 'whiteListMint' | 'freeMint' | 'publicSaleMint'

const emptyNFT: NFT = { id: '', type: '' };
const TIMEZONE = 'Asia/Taipei';

export default defineComponent({
  name: 'Home',
  components: {
    MemberCard,
    GirlSlider,
    BannerSlider,
    PurchaseButton,
    PurchaseSlider,
    PurchaseVideo,
    PurchaseDialog,
    Title,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' });
    const { vw } = useResize();

    const showPageTop = ref(false);

    const handleShowPageTop = throttle(() => {
      if (window.scrollY < 700) {
        showPageTop.value = false;
        return;
      }

      showPageTop.value = true;
    }, 200);

    const memberList = computed(() => [
      {
        url: require('@/assets/images/member1.png'),
        name: t('member.member1.name'),
        role: t('member.member1.role'),
        introduction: t('member.member1.introduction'),
      },
      {
        url: require('@/assets/images/member2.png'),
        name: t('member.member2.name'),
        role: t('member.member2.role'),
        introduction: t('member.member2.introduction'),
      },
      {
        url: require('@/assets/images/member3.png'),
        name: t('member.member3.name'),
        role: t('member.member3.role'),
        introduction: t('member.member3.introduction'),
      },
      {
        url: require('@/assets/images/member4.png'),
        name: t('member.member4.name'),
        role: t('member.member4.role'),
        introduction: t('member.member4.introduction'),
      },
      {
        url: require('@/assets/images/member5.png'),
        name: t('member.member5.name'),
        role: t('member.member5.role'),
        introduction: t('member.member5.introduction'),
      },
      {
        url: require('@/assets/images/member6.png'),
        name: t('member.member6.name'),
        role: t('member.member6.role'),
        introduction: t('member.member6.introduction'),
      },
    ]);

    const nftCardList = computed(() => {
      if (vw.value < 768) {
        return [
          require(`@/assets/images/nft-mobile-${locale.value}1.webp`),
          require(`@/assets/images/nft-mobile-${locale.value}2.webp`),
          require(`@/assets/images/nft-mobile-${locale.value}3.webp`),
        ];
      }

      return [
        require(`@/assets/images/nft-${locale.value}1.webp`),
        require(`@/assets/images/nft-${locale.value}2.webp`),
        require(`@/assets/images/nft-${locale.value}3.webp`),
      ];
    });

    const timer = ref<number | undefined>();
    const timeInterval = ref<CountdownTime>({
      days: 1,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });

    const targetTime = dayjs().tz(TIMEZONE).set({
      years: 2022,
      months: 5,
      date: 28,
      hours: 21,
      minutes: 0,
      second: 0,
    });

    const showReveal = ref(false);

    const isTimeout = computed(() => {
      const {
        days, hours, minutes, seconds,
      } = timeInterval.value;

      return Number(days) <= 0
        && Number(hours) <= 0
        && Number(minutes) <= 0
        && Number(seconds) <= 0;
    });

    const handleCountdown = () => {
      const diffTime = targetTime.diff(dayjs().tz(TIMEZONE));
      const durationTime = dayjs.duration(diffTime);

      if (isTimeout.value) {
        clearInterval(timer.value);
        return;
      }

      timeInterval.value = {
        days: targetTime.diff(dayjs().tz(TIMEZONE), 'day'),
        hours: durationTime.format('HH'),
        minutes: durationTime.format('mm'),
        seconds: durationTime.format('ss'),
      };
    };

    const isTimeOut = computed(() => dayjs().tz(TIMEZONE).isAfter(targetTime));

    const wallet = ref<Wallet>({
      address: '',
    });
    const nftList = ref<NFT[]>([]);
    const purchaseStep = ref(1);

    // 解盲 NFT 後，把 NFT 從列表中移出。
    const handleCardOpened = (data: NFT) => {
      nftList.value = filter(nftList.value, (nft) => nft.id !== data.id);
    };
    // 讀取MINT的錯誤信息
    const mintError = ref<TxResult>({
      status: undefined,
      response: undefined,
    });

    // TODO: 處理 Mint 方法，須依據 Free mint、public mint 來做切換
    const handleMint = debounce(async (type: MintType) => {
      wallet.value = await connectWallet();
      mintError.value = { status: undefined, response: undefined };

      // 確認錢包有沒有合法
      if (!checkWalletIsValid(wallet.value)) return;

      switch (type) {
        case 'whiteListMint':
          console.log('whiteListMint');
          mintError.value = await whiteListMint(wallet.value);
          break;

        case 'freeMint':
          console.log('freeMint');
          mintError.value = await freeMint(wallet.value);
          break;

        default:
          console.log('publicSaleMint');
          mintError.value = await publicSaleMint();
          break;
      }
    }, 500, {
      leading: true,
      trailing: false,
    });

    const handleRevealStepOne = async () => {
      wallet.value = await connectWallet();

      if (!checkWalletIsValid(wallet.value)) return;
      nftList.value = await getNFTs(wallet.value);
      purchaseStep.value = 2;
    };

    const selectedData = ref<NFT>(cloneDeep(emptyNFT));

    const handleCardSelect = (data: NFT) => {
      selectedData.value = data;
    };

    const readyToReveal = ref(false); // 決定是否播放解盲動畫的狀態
    const showPurchaseVideo = ref(false); // 顯示解盲影片彈窗
    const showPurchaseDialog = ref(false); // 顯示等待彈窗

    // TODO: 點擊打開盲盒後執行的 Function
    const handleRevealNft = async (data : NFT) => {
      showPurchaseVideo.value = false;
      showPurchaseDialog.value = true;
      let interval: ReturnType<typeof setTimeout>;
      // TODO: 處理解盲，需等待至與智能合約互動完畢才繼續往下執行
      await Promise.all([
        reveal(data.id),
        interval = setInterval(async () => {
          if (await isRevealed(data.id)) {
            clearInterval(interval);
            readyToReveal.value = true;
            showPurchaseDialog.value = false;
            showPurchaseVideo.value = true;
          }
        }, 1000),
      ]);
    };

    const handleVideoClose = debounce((reset = false) => {
      if (!reset) {
        showPurchaseVideo.value = false;
        return;
      }

      selectedData.value = cloneDeep(emptyNFT);
      readyToReveal.value = false;
      showPurchaseVideo.value = false;
    }, 200);

    const nftCount = ref<{
      current: number | string
      total: number | string
    }>({
      current: '0000',
      total: '3888',
    });

    // 取得 NFT 數量資訊
    const fetchCurrentNftCount = async () => {
      nftCount.value.current = await getCurrentNftCount();
      console.log('current ', nftCount.value.current);
    };
    const fetchTotalNftCount = async () => {
      nftCount.value.total = await getTotalNftCount();
    };

    onMounted(() => {
      document.dispatchEvent(new Event('render-event'));

      handleCountdown();
      fetchCurrentNftCount();
      fetchTotalNftCount();

      setInterval(() => {
        fetchCurrentNftCount();
      }, 15000);

      timer.value = 0;
      setInterval(() => {
        handleCountdown();
      }, 1000);
      window.addEventListener('scroll', handleShowPageTop);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleShowPageTop);
    });

    return {
      animateScrollTo,
      locale,
      showReveal,
      memberList,
      nftCardList,
      showPageTop,
      showPurchaseVideo,
      showPurchaseDialog,
      readyToReveal,
      purchaseStep,
      mintError,
      timeInterval,
      isTimeout,
      nftList,
      selectedData,
      nftCount,
      handleMint,
      handleRevealStepOne,
      handleRevealNft,
      handleCardSelect,
      handleCardOpened,
      handleVideoClose,
      isTimeOut,
    };
  },
});
