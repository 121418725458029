
import {
  defineComponent, onMounted, toRefs, watch,
} from 'vue';

export default defineComponent({
  name: 'PurchaseVideo',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { show } = toRefs(props);

    const handleBodyOverflow = (isShow: boolean): void => {
      if (isShow) {
        document.body.style.overflow = 'hidden';
        return;
      }
      document.body.style.overflow = 'auto';
    };

    watch(show, (newShow) => handleBodyOverflow(newShow));

    onMounted(() => {
      handleBodyOverflow(show.value);
    });

    return {
    };
  },
});
