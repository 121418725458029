// eslint-disable-next-line import/prefer-default-export
export const en = {
  navbar: {
    about: 'ABOUT',
    nft: 'NFT',
    roadmap: 'ROADMAP',
    mint: 'Reveal',
    member: 'TEAM',
    girls: 'Hey Girls',
  },
  about: {
    title: 'ABOUT MODEL',
    content: {
      1: 'Inspired by the idea of breaking Chinese cultural barriers on adult films, my buddies and I wanted create a Chinese AV culture perceived with openness, optimism and empathy. With a dream that one day we can spread the love to all Chinese worldwide in mind, Model Media was founded.',
      '2-1': 'With member count totaling to more than ',
      '2-highlight': '50 million',
      '2-2': ', Model Media has generated a substantial AV fan base in the past two years. We wanted to grow the company innovatively along with the fandom. This is why we have recently acquired a group of Blockchain experts; they will help pave way to obtaining Model Media NFT.',
      3: 'Model Media has already uplifted 50 million souls worldwide. In order to gain a deeper understanding of what models do, we have incorporated role play. Come check it out in Model Eros Village.',
    },
    village: {
      title: {
        mobile: '— Model village —',
        desktop: '— Model village',
      },
      content: {
        1: 'Embark on a new adventure where there is much to do and much to decide. On what seems like a typical day in Model Village, ',
        '1-highlight': 'the Chinese Zodiac was suddenly integrated into the village.',
        2: 'In a village that lacked men, the 12 original female villagers are in desperate need of a hand. They need your help to build the village.Become one of seven roles in the village: Stranger, Wanderer, Villager, Hunter, Warrior, Knight, Village Chief',
        3: ' Meet various models around the world. Interact with the models online and in real person. And realize your fantasy.',
        '3-highlight': 'Grow your village, your way.',
      },
    },
  },
  nft: {
    title: 'NFT UTILITY',
    card1: {
      subtitle: 'Owner',
      title: 'Selection',
      mission1: {
        title: 'Warrior Mission 1',
        content: 'Mint your NFT, you can unlock the permanent Model Media membership via Model APP.',
      },
      mission2: {
        title: 'Warrior Mission 2',
        content: 'NFT holder will unlock the video profit sharing of Model TV.',
      },
      mission3: {
        title: 'Warrior Mission 3',
        content: 'Participating in the mission 2 and Airdrop event to unlock more NFT utilities.',
      },
    },
    card2: {
      subtitle: 'Suggestions',
      title: 'for Metaverse',
      mission1: {
        title: 'Village Mission 1',
        content: 'Starting with the Model Media VR World, the NFT holder can provide the idea and script partilly join our porn filming.',
      },
      mission2: {
        title: 'Village Mission 2',
        content: 'Join our 3 days global meetup event with all NFT holders in real life.',
      },
      mission3: {
        title: 'Village Mission 3',
        content: 'Model Media VR World launches and unlock profit sharing function.',
      },
    },
    card3: {
      subtitle: 'Model Media',
      title: 'Eros Village',
      mission1: {
        title: 'Metaverse Mission',
        content: 'You will partially own Model Media Eros Village, join our Village tour, Theater and be a part of Village Community.',
      },
    },
  },
  roadmap: {
    title: 'ROADMAP',
    stage1: {
      title: 'Stage 1',
      content: 'Mint your NFT and join Model Eros Village with WEB 3.0 brands for 3,888 NFTs avatars. Every NFT holder can get exclusive membership, airdrops and other benefits.',
    },
    stage2: {
      title: 'Stage 2',
      content: 'Unlock permanent Model TV membership to watch video and earn USDT. The NFT holders will receive an airdrop to upgrade your NFT. The holders will receive an invitation to join 2022 Christmas Model Village party.',
    },
    stage3: {
      title: 'Stage 3',
      content: 'Enjoy the drinks and crazy party with all MEV members from all around the world for 3days in Southeast Asia!',
    },
    stage4: {
      title: 'Stage 4',
      content: 'Join MEV VR world to enter the Metaverse, build a 3D Model Village and witness the release of Model VR products and earn USDT by watching VR videos.',
    },
    stage5: {
      title: 'Stage 5',
      content: 'MEV NFT 2.0 plan, the Model Metaverse village build up in Sandbox. With more Model products membership and real-life meetups, parties, art exhibitions and airdrops!',
    },
  },
  mint: {
    title: 'MINT',
    reveal: 'REVEAL',
    comingSoon: 'COMING SOON',
    warn: {
      1: 'If it cannot be successfully minted, it may be due to the following reasons:',
      2: '1. The network connection is unstable   2. The wallet address is invalid/not whitelisted   3. The balance is insufficient',
      3: '4. The NFT has been minted using this wallet   5. The browser that does not support this page in used”',
    },
  },
  member: {
    title: 'TEAM',
    member1: {
      name: 'Mr.P',
      role: 'Founder',
      introduction: 'A man who loves traveling. Building the brand of Model Media has been a hobby of his. While many doubted the arduous undertaking, he kept fighting with great tenacity. In the quest to redefine pornography, he gathers the best to fight alongside him.',
    },
    member2: {
      name: 'Ghost Money',
      role: 'Product Manager',
      introduction: 'An avid cryptocurrency trader since 2018. A Model Media super fan who became a business partner and took on the role of NFT project leader in 2022. He is the architect of Model Media metaverse.',
    },
    member3: {
      name: 'Dicky Chiu',
      role: 'Engineer',
      introduction: 'With more than a decade of experience in applied engineering, he is able to guide and consult the team to a clear path forward. Always proactively learning engineering skills on his free time, he challenges himself on a limitless life of learning.',
    },
    member4: {
      name: 'Anson Yu',
      role: 'Engineer',
      introduction: 'Having been actively working in the block chain for 8 years, he is one of the most experienced in the field. He is also a deep thinker who doesn’t mind tackling difficult tasks. “Easy or hard, you might as well find out yourself.” In his free time, he enjoys creating bots and interacting with them.',
    },
    member5: {
      name: 'Grace',
      role: 'Artist',
      introduction: 'Participated in Taiwan anime, Netflix’s original anime production, and various Chinese comics, she is a talented artist who is committed to tell stories in the most visually savory fashion. She has been a big part of the artwork designs in the Model Eros Village.',
    },
    member6: {
      name: 'Jin Nine',
      role: 'Brand Designer',
      introduction: 'The Visual Director of Model Media is a philosophical thinker. He enjoys filming paintings. Having met Mr. P, he began his journey in the AV industry.',
    },
  },
  girls: {
    title: 'Hey Girls',
  },
  top: 'PAGE TOP',
};
