
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MemberCard',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
    };
  },
});
