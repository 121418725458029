import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "purchase-button",
    type: "button",
    style: _normalizeStyle(_ctx.buttonStyle)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}