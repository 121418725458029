import {
  ref, onMounted, onUnmounted, Ref,
} from 'vue';
import { throttle } from 'lodash';

interface Resp {
  vw: Ref<number>,
  vh: Ref<number>
}

// eslint-disable-next-line import/prefer-default-export
export function useResize(): Resp {
  const vw = ref(0);
  const vh = ref(0);

  function update() {
    vw.value = window.innerWidth;
    vh.value = window.innerHeight;
  }

  const throttleUpdate = throttle(update, 200);

  onMounted(() => {
    vw.value = window.innerWidth;
    vh.value = window.innerHeight;

    window.addEventListener('resize', throttleUpdate);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', throttleUpdate);
  });

  return { vw, vh };
}
