
import { Autoplay, EffectFade } from 'swiper';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { useResize } from '@/composables/useResize';
import { ref } from 'vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiper = ref<typeof Swiper | null>(null);

    const { vw } = useResize();

    const onSwiper = (swiperInstance: typeof Swiper) => {
      swiper.value = swiperInstance;
    };

    return {
      vw,
      swiper,
      onSwiper,
      modules: [Autoplay, EffectFade],
    };
  },
};
