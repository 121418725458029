
import { defineComponent } from 'vue';
import 'scroll-triggers';

export default defineComponent({
  name: 'Footer',
  setup() {
    return {
    };
  },
});
