
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';

type ButtonType = 'default' | 'cancel'

export default defineComponent({
  name: 'PurchaseButton',
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'default',
    },
  },
  setup(props) {
    const { type } = toRefs(props);

    const buttonStyle = computed(() => {
      const url = type.value === 'default'
      // eslint-disable-next-line global-require
        ? require('@/assets/images/purchase-btn.webp')
      // eslint-disable-next-line global-require
        : require('@/assets/images/purchase-cancel-btn.webp');

      return {
        'background-image': `url(${url})`,
        color: type.value === 'default' ? '#6C0200' : '#EBEBEB',
      };
    });

    return {
      buttonStyle,
    };
  },
});
