
import {
  defineComponent, onMounted, ref, toRefs, watch, PropType,
} from 'vue';
import PurchaseButton from '@/components/PurchaseButton.vue';
import { NFT } from '@/interfaces';
import { isRevealed, getAnimationURI } from '@/utils';

export default defineComponent({
  name: 'PurchaseVideo',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object as PropType<NFT>,
      required: true,
    },
    readyToOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'finish', 'open'],
  components: {
    PurchaseButton,
  },
  setup(props, { emit }) {
    const { show, data } = toRefs(props);
    const loopVideo = ref<HTMLVideoElement | null>(null);
    // const startOpen = ref(false);
    const showResult = ref(false);
    const animationURI = ref();

    const handleBodyOverflow = (isShow: boolean): void => {
      if (isShow) {
        document.body.style.overflow = 'hidden';
        return;
      }
      document.body.style.overflow = 'auto';
    };

    // const handleLoopEnded = () => {
    //   if (startOpen.value) {
    //     setTimeout(() => {
    //       showResult.value = true;
    //       emit('finish', data.value);
    //     }, 500);
    //     return;
    //   }
    //   if (readyToOpen.value) {
    //     // eslint-disable-next-line global-require
    //     loopVideo.value!.src = require('@/assets/images/purchase-normal-open.mp4');
    //     startOpen.value = true;
    //     return;
    //   }
    //   loopVideo.value!.play();
    // };

    const handleOpenEnded = () => {
      setTimeout(() => {
        showResult.value = true;
        emit('finish', data.value);
      }, 500);
    };

    const handleConfirm = () => {
      showResult.value = false;
      emit('close', true);
    };

    const handleOpen = async () => {
      animationURI.value = await emit('open', data.value);
      const interval = setInterval(async () => {
        const isReveal = await isRevealed(data.value.id);
        if (isReveal) {
          clearInterval(interval);
          animationURI.value = await getAnimationURI(data.value.id);
        }
      }, 1000);
    };
    watch(show, (newShow) => handleBodyOverflow(newShow));

    onMounted(() => {
      handleBodyOverflow(show.value);
    });

    return {
      loopVideo,
      // handleLoopEnded,
      handleOpenEnded,
      handleConfirm,
      handleOpen,
      showResult,
      animationURI,
    };
  },
});
