
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Title',
  setup() {
    return {
    };
  },
});
