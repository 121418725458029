
import { debounce } from 'lodash';
import {
  computed,
  defineComponent, nextTick, onMounted, onUnmounted, ref,
} from 'vue';
import animateScrollTo from 'animated-scroll-to';
import { useI18n } from 'vue-i18n';

const debounceDefault = (callback: any) => debounce(callback, 200, { leading: true });

const scrollTo = (domName: string) => {
  const el = document.querySelector(domName) as HTMLElement;
  if (domName === '.girl') {
    animateScrollTo(el, { verticalOffset: -80 });
    return;
  }
  animateScrollTo(el, { verticalOffset: -30 });
};

export default defineComponent({
  name: 'Header',
  setup() {
    const { locale, t } = useI18n({ useScope: 'global' });

    const navList = computed(() => [
      {
        el: '.about',
        text: t('navbar.about'),
      },
      {
        el: '.nft',
        text: t('navbar.nft'),
      },
      {
        el: '.roadmap',
        text: t('navbar.roadmap'),
      },
      {
        el: '.mint',
        text: t('navbar.mint'),
      },
      {
        el: '.member',
        text: t('navbar.member'),
      },
      {
        el: '.girl',
        text: t('navbar.girls'),
      },
    ]);

    const showMenu = ref(false);
    const showLanguage = ref(false);
    const header = ref<HTMLElement | null>(null);
    const oldScrollY = ref<number>(0);
    const headerTranslateY = ref('translateY(0px)');

    const toggleMenu = debounceDefault(async () => {
      if (showLanguage.value) {
        showLanguage.value = false;
        await nextTick();
      }
      showMenu.value = !showMenu.value;
    });

    const toggleLanguage = debounceDefault(async () => {
      if (showMenu.value) {
        showMenu.value = false;
        await nextTick();
      }
      showLanguage.value = !showLanguage.value;
    });

    const closeMenuAndScroll = (domName: string) => {
      showMenu.value = false;
      scrollTo(domName);
    };

    const closeMenuAndChangeLanguage = (localeName: string) => {
      showLanguage.value = false;
      locale.value = localeName;
    };

    // Header 滾動處理
    const getTranslateY = (dom: HTMLElement): number => {
      let transform = dom.style.transform || 0;

      if (transform && typeof transform === 'string') {
        transform = transform.replace('translateY(', '');
        transform = transform.replace('px)', '');
      }

      return parseInt(transform as string, 10);
    };

    const handleShowHeader = () => {
      const { scrollY = 0 } = window;
      const diffOfScrollY = Math.min(Math.abs(scrollY - oldScrollY.value), 15);
      const nowTranslateY = getTranslateY(header.value as HTMLElement);
      const headerHeight = 80;
      const isScrollToTop = oldScrollY.value > scrollY;
      oldScrollY.value = scrollY;

      if (isScrollToTop || !scrollY) {
        // 向上滾動邏輯
        if (nowTranslateY >= 0 || !scrollY) {
          headerTranslateY.value = 'translateY(0px)';
          return;
        }

        const newY = nowTranslateY + diffOfScrollY >= 0 ? 0 : nowTranslateY + diffOfScrollY;

        headerTranslateY.value = `translateY(${newY}px)`;
      } else {
        // 向下滾動邏輯
        if (nowTranslateY <= -headerHeight) {
          headerTranslateY.value = `translateY(${-headerHeight}px)`;
          return;
        }

        headerTranslateY.value = `translateY(${nowTranslateY - diffOfScrollY}px)`;
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleShowHeader);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleShowHeader);
    });

    return {
      header,
      headerTranslateY,
      navList,
      showMenu,
      showLanguage,
      toggleMenu,
      toggleLanguage,
      closeMenuAndScroll,
      closeMenuAndChangeLanguage,
      locale,
    };
  },
});
