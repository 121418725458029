
import { defineComponent, PropType } from 'vue';
import { NFT } from '@/interfaces';

export default defineComponent({
  name: 'PurchaseCard',
  props: {
    data: {
      type: Object as PropType<NFT>,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
    };
  },
});
