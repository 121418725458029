// eslint-disable-next-line import/prefer-default-export
export const cn = {
  navbar: {
    about: '关于麻豆',
    nft: 'NFT赋能',
    roadmap: '路线图',
    mint: '解盲NFT',
    member: '团队成员',
    girls: 'Hey Girls',
  },
  about: {
    title: '关于麻豆',
    content: {
      1: '麻豆是致力于让全球华人都能够共享的AV，起初是由几个环游世界的年轻人，于旅途中得到了启发，突破大家对”色”的定义。',
      '2-1': '随着品牌2年半的发展，今年已经取得了',
      '2-highlight': '5,000万',
      '2-2': '的会员人数，麻豆创办人Mr.P开始思考如何与用户共同经营品牌。经由多方面思考与契机，遇到优质的区块链团队和艺术人才，辅助全新团队打造麻豆NFT。过程中我们想要珍惜所有的女模，并与更多志同道合的导演和伙伴们一起创造属于华语的AV文化。',
      3: '麻豆在现实生活中治愈了超过5,000万个灵魂，为了让更多人了解成人与情趣市场的多元发展，Model Eros Village【麻豆村】在粉丝的呼唤之下而诞生。',
    },
    village: {
      title: {
        mobile: '— 麻豆村 —',
        desktop: '麻豆村',
      },
      content: {
        1: '【麻豆村】创立之初万物生生不息，',
        '1-highlight': '某日天空下凡东方12生肖进入麻豆村庄与之融合。',
        2: '结合初创12位女村民，准备开始发展自己的村落文化，缺乏男丁的麻豆村开始广纳各方战士一起推广麻豆村。',
        3: '（路人Stranger）（浪人Wanderer）（村民Villager）（猎人Hunter）（战士Warrior）（ 骑士Kinght）（村长Village head）将会带着村民一起发掘我们心中幻想的各个世界打造一个线上与线下的村落，让村民体验麻豆的各国村落文化，体验各地的女村民，让一切成真。',
        '3-highlight': '村子将会有7种身份',
      },
    },
  },
  nft: {
    title: 'NFT赋能',
    card1: {
      subtitle: '村落启动计画',
      title: '拥有你的NFT',
      mission1: {
        title: '战士任务 1',
        content: '铸造NFT取得麻豆APP永久会员。',
      },
      mission2: {
        title: '战士任务 2',
        content: '持有NFT解锁麻豆TV分润赋能。',
      },
      mission3: {
        title: '战士任务 3',
        content: '参与活动空投升级你的NFT。',
      },
    },
    card2: {
      subtitle: '元宇宙启动计画',
      title: '麻豆元宇宙建构',
      mission1: {
        title: '村落任务 1',
        content: '虚拟世界VR计画启动，参与脚本制作与女优征选。',
      },
      mission2: {
        title: '村落任务 2',
        content: '带着你的专属NFT参加3天2夜麻豆村全球粉丝见面会。',
      },
      mission3: {
        title: '村落任务 3',
        content: '麻豆VR上线，解锁分润与永久会员赋能。',
      },
    },
    card3: {
      subtitle: '影城启动计画',
      title: '麻豆情趣娱乐村',
      mission1: {
        title: '元宇宙任务',
        content: '参与麻豆元宇宙情趣娱乐村、电影院、成人旅游介绍、与社区建设。',
      },
    },
  },
  roadmap: {
    title: '路线图',
    stage1: {
      title: 'Stage 1',
      content: '进入NFT世界与 WEB 3.0 品牌合作，铸造3,888件NFT女优头像。每位NFT持有者都可以获得独家商品会员、空投等福利。',
    },
    stage2: {
      title: 'Stage 2',
      content: '解锁永久麻豆TV会员赋能、麻豆TV分润制度，获得空投并升级你的NFT，你将收到线下派对邀请函。',
    },
    stage3: {
      title: 'Stage 3',
      content: '带着你的升级女优，受邀于圣诞节和与其他NFT持有者一同参加实体圣诞麻豆村派对，与女优和全世界的麻豆伙伴们一起狂欢。',
    },
    stage4: {
      title: 'Stage 4',
      content: '加入MEV Metaverse一同进入元宇宙，建构3D麻豆村并见证麻豆VR产品发表和VR影片分润。',
    },
    stage5: {
      title: 'Stage 5',
      content: 'MEV NFT 2.0计画，更多的Model产品赋能解锁与线下见面会、派对、艺术展览与更多的空投。',
    },
  },
  mint: {
    title: '铸造NFT',
    reveal: '解盲NFT',
    comingSoon: 'COMING SOON',
    warn: '若无法成功铸造，可能为以下原因 1. 网路连线不稳定 2.钱包或白名单地址无效 3.馀额不足 4.已使用此钱包铸造过NFT 5.使用不支援此页面的浏览器',
  },
  member: {
    title: '团队成员',
    member1: {
      name: 'Mr.P',
      role: '品牌创办人',
      introduction: '喜爱旅游的男子，做自己喜爱的事情，做麻豆这个品牌就是他爱的事情，所有人都不看好他，还好他一直逆流而上。想要突破大家对色的定义，于是决定集结更多的人来挑战未来。',
    },
    member2: {
      name: 'Ghost Money',
      role: '项目经理',
      introduction: '2017年开始接触加密货币 2018年从事比特币期货永续合约高频交易 2022年加入麻豆NFT团队担任Project Lead。麻豆忠实粉丝，从粉丝变伙伴，跃跃欲试打造下个麻豆宇宙。',
    },
    member3: {
      name: 'Dicky Chiu',
      role: '团队工程师',
      introduction: '总共10年的本地与区域性技术人生，活跃的在网上学习技术，贯策学不完的心态一直学一直学，挑战无极限的人生。',
    },
    member4: {
      name: 'Anson Yu',
      role: '团队工程师',
      introduction: '活跃从事区块链近8年，喜欢做个机器人跟自己聊天，喜欢验证很难的事情，过程中一堆人说很难或很简单，不如自己验证吧。',
    },
    member5: {
      name: 'Grace',
      role: '艺术家',
      introduction: '曾参予台湾、网飞等原创动画制作，华人漫画家，以用画面说故事为毕生志业，Mr.P 喜爱华人的才华，希望更多人可以看到他的才华，终于找到她。开始共创麻豆村。',
    },
    member6: {
      name: 'Jin Nine',
      role: '品牌设计师',
      introduction: '麻豆视觉总监，喜欢拍摄画画喜欢研究一条线怎么走得直，结果遇到Mr.P 于是就开始了一场成人界的直路。',
    },
  },
  girls: {
    title: 'Hey Girls',
  },
  top: 'PAGE TOP',
};
