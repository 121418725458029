// eslint-disable-next-line import/prefer-default-export
export const tw = {
  navbar: {
    about: '關於麻豆',
    nft: 'NFT賦能',
    roadmap: '路線圖',
    mint: '解盲NFT',
    member: '團隊成員',
    girls: 'Hey Girls',
  },
  about: {
    title: '關於麻豆',
    content: {
      1: '麻豆是致力於讓全球華人都能夠共享的AV，起初是由幾個環遊世界的年輕人，於旅途中得到了啟發，突破大家對”色”的定義。',
      '2-1': '随著品牌2年半的發展，今年已經取得了',
      '2-highlight': '5,000萬',
      '2-2': '的會員人數，麻豆創辦人Mr.P開始思考如何與用户共同經營品牌。經由多方面思考與契機，遇到優質的區塊鏈團隊和藝術人才，輔助全新團隊打造麻豆NFT。過程中我們想要珍惜所有的女模，並與更多志同道合的導演和夥伴們一起創造屬於華語的AV文化。',
      3: '麻豆在現實生活中治癒了超過5,000萬個靈魂，為了讓更多人了解成人與情趣市場的多元發展，Model Eros Village【麻豆村】在粉絲的呼喚之下而誕生。',
    },
    village: {
      title: {
        mobile: '— 麻豆村 —',
        desktop: '麻豆村',
      },
      content: {
        1: '【麻豆村】創立之初萬物生生不息，',
        '1-highlight': '某日天空下凡東方12生肖進入麻豆村莊與之融合。',
        2: '結合初創12位女村民，準備開始發展自己的村落文化，缺乏男丁的麻豆村開始廣納各方戰士一起推廣麻豆村。',
        3: '（路人Stranger）（浪人Wanderer）（村民Villager）（獵人Hunter）（戰士Warrior）（ 騎士Kinght）（村長Village head）將會带著村民一起發掘我們心中幻想的各個世界打造一個線上與線下的村落，讓村民體驗麻豆的各國村落文化，體驗各地的女村民，讓一切成真。',
        '3-highlight': '村子將會有7種身份',
      },
    },
  },
  nft: {
    title: 'NFT賦能',
    card1: {
      subtitle: '村落啟動計畫',
      title: '擁有你的NFT',
      mission1: {
        title: '戰士任務 1',
        content: '鑄造NFT取得麻豆APP永久會員。',
      },
      mission2: {
        title: '戰士任務 2',
        content: '持有NFT解鎖麻豆TV分潤賦能。',
      },
      mission3: {
        title: '戰士任務 3',
        content: '參與活動空投升級你的NFT。',
      },
    },
    card2: {
      subtitle: '元宇宙啟動計畫',
      title: '麻豆元宇宙建構',
      mission1: {
        title: '村落任務 1',
        content: '虛擬世界VR計畫啟動，參與腳本製作與女優徵選。',
      },
      mission2: {
        title: '村落任務 2',
        content: '帶著你的專屬NFT參加3天2夜麻豆村全球粉絲見面會。',
      },
      mission3: {
        title: '村落任務 3',
        content: '麻豆VR上線，解鎖分潤與永久會員賦能。',
      },
    },
    card3: {
      subtitle: '影城啟動計畫',
      title: '麻豆情趣娛樂村',
      mission1: {
        title: '元宇宙任務',
        content: '參與麻豆元宇宙情趣娛樂村、電影院、成人旅遊介紹、與社區建設。',
      },
    },
  },
  roadmap: {
    title: '路線圖',
    stage1: {
      title: 'Stage 1',
      content: '進入NFT世界與 WEB 3.0 品牌合作，鑄造3,888件NFT女優頭像。每位NFT持有者都可以獲得獨家商品會員、空投等福利。',
    },
    stage2: {
      title: 'Stage 2',
      content: '解鎖永久麻豆TV會員賦能、麻豆TV分潤制度，獲得空投並升級你的NFT，你將收到線下派對邀請函。',
    },
    stage3: {
      title: 'Stage 3',
      content: '帶著你的升級女優，受邀於聖誕節和與其他NFT持有者一同參加實體聖誕麻豆村派對，與女優和全世界的麻豆夥伴們一起狂歡。',
    },
    stage4: {
      title: 'Stage 4',
      content: '加入MEV Metaverse一同進入元宇宙，建構3D麻豆村並見證麻豆VR產品發表和VR影片分潤。',
    },
    stage5: {
      title: 'Stage 5',
      content: 'MEV NFT 2.0計畫，更多的Model產品賦能解鎖與線下見面會、派對、藝術展覽與更多的空投。',
    },
  },
  mint: {
    title: '鑄造NFT',
    reveal: '解盲NFT',
    comingSoon: 'COMING SOON',
    warn: '若無法成功鑄造，可能為以下原因 1. 網路連線不穩定 2.錢包或白名單地址無效 3.餘額不足 4.已使用此錢包鑄造過NFT 5.使用不支援此頁面的瀏覽器',
  },
  member: {
    title: '團隊成員',
    member1: {
      name: 'Mr.P',
      role: '品牌創辦人',
      introduction: '喜愛旅遊的男子，做自己喜愛的事情，做麻豆這個品牌就是他愛的事情，所有人都不看好他，還好他一直逆流而上。想要突破大家對色的定義，於是決定集結更多的人來挑戰未來。',
    },
    member2: {
      name: 'Ghost Money',
      role: '項目經理',
      introduction: '2017年開始接觸加密貨幣 2018年從事比特幣期貨永續合約高頻交易 2022年加入麻豆NFT團隊擔任Project Lead。麻豆忠實粉絲，從粉絲變夥伴，躍躍欲試打造下個麻豆宇宙。',
    },
    member3: {
      name: 'Dicky Chiu',
      role: '團隊工程師',
      introduction: '總共10年的本地與區域性技術人生，活躍的在網上學習技術，貫策學不完的心態一直學一直學，挑戰無極限的人生。',
    },
    member4: {
      name: 'Anson Yu',
      role: '團隊工程師',
      introduction: '活躍從事區塊鏈近8年，喜歡做個機器人跟自己聊天，喜歡驗證很難的事情，過程中一堆人說很難或很簡單，不如自己驗證吧。',
    },
    member5: {
      name: 'Grace',
      role: '藝術家',
      introduction: '曾參予台灣、網飛等原創動畫製作，華人漫畫家，以用畫面說故事為畢生志業，Mr.P 喜愛華人的才華，希望更多人可以看到他的才華，終於找到她。開始共創麻豆村。',
    },
    member6: {
      name: 'Jin Nine',
      role: '品牌設計師',
      introduction: '麻豆視覺總監，喜歡拍攝畫畫喜歡研究一條線怎麼走得直，結果遇到Mr.P 於是就開始了一場成人界的直路。',
    },
  },
  girls: {
    title: 'Hey Girls',
  },
  top: 'PAGE TOP',
};
