/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/prefer-default-export */
import { NFT, Wallet, TxResult } from '@/interfaces';
import { ethers } from 'ethers';
import ABI from '../contract/ABI.json';
import { address } from '../contract/address.json';
import server from '../constant/config';
/**
 * TODO: 連接錢包
 */
export const connectWallet = async (): Promise<Wallet> => {
  let walletConnected;
  if ((window as any).ethereum) {
    walletConnected = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
  }
  const wallet = { address: walletConnected[0] };
  return wallet;
};

/**
 * TODO: 檢查錢包是否為有效、合法
 */
export const checkWalletIsValid = (wallet: Wallet): boolean => {
  const isValid = ethers.utils.isAddress(wallet.address);
  return isValid;
};

/*
  從API拿取Proof
*/
const getPresaleProof = async (wallet: Wallet) => {
  const proof = await fetch(`${server.APIPath}/presaleProof/?address=${wallet.address}`);
  const data = await proof.json();
  return data.result;
};

const getFreeProof = async (wallet: Wallet) => {
  const proof = await fetch(`${server.APIPath}/freemintProof/?address=${wallet.address}`);
  const data = await proof.json();
  return data.result;
};

/**
 * TODO: FreeMint 功能接口
 */
// eslint-disable-next-line consistent-return
export const freeMint = async (wallet: Wallet): Promise<TxResult> => {
  let resultArr = { status: false, response: '' };
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(address, ABI, signer);
  const connect = contract.connect(signer);
  try {
    const tx = await connect.freeMint(1, await getFreeProof(wallet), { value: ethers.utils.parseEther('0') });
    const status = await tx.wait();
    if (status.status === 1) {
      resultArr = { status: true, response: 'Successfully Mint!' };
    }
  } catch (e: any) {
    const error = e.error.message.replace('execution reverted: ', '');
    if (error.includes('insufficient funds')) {
      resultArr = { status: false, response: 'Not enough funds' };
    } else resultArr = { status: false, response: error };
  }
  return resultArr;
};

/**
 * TODO: whiteListMint 功能接口
 */
// eslint-disable-next-line consistent-return
export const whiteListMint = async (wallet: Wallet): Promise<TxResult> => {
  let resultArr = { status: false, response: '' };
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(address, ABI, signer);
  const connect = contract.connect(signer);
  try {
    const tx = await connect.presaleMint(1, await getPresaleProof(wallet), { value: ethers.utils.parseEther('0.08') });
    const status = await tx.wait();
    if (status.status === 1) {
      resultArr = { status: true, response: 'Successfully Mint!' };
    }
  } catch (e: any) {
    const error = e.error.message.replace('execution reverted: ', '');
    if (error.includes('insufficient funds')) {
      resultArr = { status: false, response: 'Not enough funds' };
    } else resultArr = { status: false, response: error };
  }
  console.log(resultArr);
  return resultArr;
};

/**
 * TODO: publicSaleMint 功能接口
 */
// eslint-disable-next-line consistent-return
export const publicSaleMint = async (): Promise<TxResult> => {
  let resultArr = { status: false, response: '' };
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(address, ABI, signer);
  const connect = contract.connect(signer);
  try {
    const tx = await connect['mint(uint32)'](1, { value: ethers.utils.parseEther('0.1') });
    const status = await tx.wait();
    if (status.status === 1) {
      resultArr = { status: true, response: 'Successfully Mint!' };
    }
  } catch (e: any) {
    const error = e.error.message.replace('execution reverted: ', '');
    if (error.includes('insufficient funds')) {
      resultArr = { status: false, response: 'Not enough funds' };
    } else resultArr = { status: false, response: error };
  }
  console.log(resultArr);
  return resultArr;
};

const editNumTo4Digit = (num : string) => {
  let result;
  switch (num.length) {
    case 1:
      result = `000${num}`;
      break;
    case 2:
      result = `00${num}`;
      break;
    case 3:
      result = `0${num}`;
      break;
    default:
      result = `${num}`;
  }
  return result;
};

// CHECK WHETHER NFT REVEALED OR NOT
export const isRevealed = async (tokenID : string) => {
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(address, ABI, signer);
  const checkReveal = await contract.isRevealed(tokenID);
  return checkReveal;
};

// GET ANIMATION URI FOR REVEAL MP4
// eslint-disable-next-line consistent-return
export const getAnimationURI = async (tokenID : string) => {
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(address, ABI, signer);
  const uri = await contract.tokenURI(tokenID);
  const request = await fetch(`${server.IPFSGateway}/ipfs/${uri.substring(7)}`);
  const data = await request.json();
  const animationURI = `${server.IPFSGateway}/ipfs/${data.animation_url.substring(7)}`;
  return animationURI;
};

/**
 * TODO: 智能合約解盲功能接口
 */
export const reveal = async (tokenID : string) => {
  // REVEAL
  await fetch(`${server.APIPath}/reveal/?tokenID=${tokenID}`);
};

/**
 * TODO: 取得 NFT 資料（未解盲）
 */
export const getNFTs = async (wallet : Wallet): Promise<NFT[]> => {
  const tokenArr : NFT[] = [];
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(address, ABI, signer);
  const tx = await contract.balanceOf(wallet.address);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < tx; i++) {
    const tokenID = await contract.tokenOfOwnerByIndex(wallet.address, i);
    const checkReveal = await contract.isRevealed(tokenID);
    if (!checkReveal) {
      const uri = await contract.tokenURI(tokenID);
      const request = await fetch(`${server.IPFSGateway}/ipfs/${uri.substring(7)}`);
      const data = await request.json();
      const type = data.image.includes('normal') ? 'normal' : (data.image.includes('gold') ? 'gold' : 'zombie');
      tokenArr.push({ id: editNumTo4Digit(tokenID.toString()), type });
    }
  }
  return tokenArr;
};

/**
 * TODO: 取得 NFT 已購買數量
 */
export const getCurrentNftCount = async (): Promise<number | string> => {
  const count = await fetch(`${server.APIPath}/getCurrentNftCount`);
  const data = await count.json();
  const result = ethers.BigNumber.from(data.result).toString();
  return editNumTo4Digit(result);
};

/**
 * TODO: 取得 NFT 總數
 */
export const getTotalNftCount = async (): Promise<number | string> => {
  const count = await fetch(`${server.APIPath}/getTotalNftCount`);
  const data = await count.json();
  return editNumTo4Digit(data.result.toString());
};
